import './sidebar.css'
import { Link, useLocation } from 'react-router-dom';
import { useAppState } from '../../state';

import { ExitToAppOutlined } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';

export default function Sidebar({ routes, path }) {
    const sidebarRoutes = routes.filter(route => !!route.icon);
    const { signOut, user } = useAppState();
    const location = useLocation();

    return (
        <div className='sidebar'>
            <div className="sidebarWrapper">
                <div className="sidebarMenu">
                    <ul className="sidebarlist">
                        {sidebarRoutes.map((route, index) => {
                            return (
                                <Link to={route.path} className='link' key={index}>
                                    <li className={location.pathname === route.path ? "sidebarlistItem active" : "sidebarlistItem"}>
                                        {route.icon}
                                        {/* {route.title} */}
                                    </li>
                                </Link>
                            )
                        })}
                        <li className="sidebarlistItem" onClick={signOut}>
                            <ExitToAppOutlined fontSize="large" />
                        </li>
                        <li className="p-lr-5" >
                            <Avatar alt="Remy Sharp" title={user.name} src={user.picture} />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
