// import './Interaction.css'
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import logo from '../images/logo.svg';
import axios from '../../helpers/request';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';


import { TimelapseOutlined, KeyboardBackspace } from '@material-ui/icons';

import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// import { TimelapseOutlined } from '@mui/icons-material';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// import { DataGrid } from '@mui/x-data-grid';
// import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  toolbar: theme.toolbar,
  actionButton: theme.actionButton,
  tableHeader: {
    margin: '0px'
  },
  paper: {
    minHeight: '200px',
    '& .customRowTable': {
      '& .meeting-archive--true': {
        backgroundColor: '#FFEAE9',
      },
    },
    '& .Flip': {
      '& .MuiDataGrid-window': {
        transform: 'rotateX(180deg)',
        top: 'auto !important'
      },
      '& .MuiDataGrid-dataContainer': {
        transform: 'rotateX(180deg)'
      }
    }
  },
  detailedTable: {
    '&.MuiTableContainer-root': {
      overflow: 'hidden'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
      borderBottom: 'none'
    }
  },
  tableLabel: {
    whiteSpace: 'nowrap',
    width: '30%'
  },
  tableButton: {
    width: '50%'
  },
  sectionTitle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  centerText: {
    textAlign: 'center'
  },
  dangerText: {
    color: theme.palette.error.main
  }
}));

export default function Interaction() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [meeting, setMeeting] = useState({});
  const { meetingId } = useParams();
  const [search, setSearch] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (meetingId) {
      getMeeting(meetingId);
      getParticipants(meetingId);
    } else {
      history.replace('/');
    }
  }, [meetingId, history])

  const getMeeting = (meetingId) => {
    axios.get('/api/meeting/' + meetingId).then(async (response) => {
      if (response && response.status === 200 && response.data) {
        setMeeting(response.data);
        // if (response.data.room_id) {
        //   getParticipants(response.data.room_id);
        // }
      } else {
        throw new Error(response?.error);
      }
    });
  }

  const getParticipants = (meetingId) => {
    let searchParams = {
      search: search
    };
    axios.get('/internal/meeting/' + meetingId + '/participant').then(async (response) => {
      if (response && response.status === 200) {
        // console.log(response.data);
        setData(response.data)
      } else {
        throw new Error(response?.error);
      }
    });
  }


  const downloadRecordingFile = (key, roomid) => {
    axios.get('/internal/recording/download', { params: { key, roomid } }).then(async (response) => {
      if (response && response.status === 200) {
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.recording_file_url;
        // document.body.appendChild(link);
        link.click();
        // document.body.removeChild(link);
      } else {
        console.log(response);
        alert("Unable to download recording file")
      }
    });
  }

  const downloadTranscriptFile = (key) => {
    axios.get('/api/recording/url', { params: { key } }).then(async (response) => {
      if (response && response.status === 200 && response.data && response.data.transcript_status === "COMPLETED") {
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.transcript_file_url;
        // document.body.appendChild(link);
        link.click();
        // document.body.removeChild(link);
      } else {
        console.log(response);
        alert("Unable to download recording file")
      }
    });
  }

  const downloadFile = (keyType) => {
    const { meeting_id, room_id } = meeting;

    axios.get('/api/meeting/' + meeting_id + '/recordings').then(async (response) => {
      if (response && response.status === 200 && response.data.length) {
        const { key } = response.data[0];

        if (keyType === "recording") {
          downloadRecordingFile(key, room_id);
        } else if (keyType === "transcript") {
          downloadTranscriptFile(key);
        }
      } else {
        console.log(response);
        alert("Unable to download recording file")
      }
    }, async (error) => {
      console.log(error?.response?.data?.message)
      alert(error?.response?.data?.message);
    });
  }

  const columns = [
    // {
    //   field: 'email', headerName: 'Participant Email', width: 200
    // },
    // {
    //   field: 'phone_number', headerName: 'Participant Phone Number', width: 200, sortable: false
    // },
    {
      field: 'identity', headerName: 'Participant Display Name', width: 200,
      valueGetter: ({ value }) => value ? value.startsWith('+') ? value.split('-')[0] : value.split('###')[0] : ''
    },
    {
      field: 'mode', headerName: 'Mode', width: 120, sortable: false,
      valueGetter: (params) => (params.row.identity && params.row.identity.includes("###")) ? (params.row.identity.split('###')[0] === "Chaperoning Mode" || params.row.identity.split('###')[1].startsWith('CP##')) ? "Chaperone" : "Normal" : ''
    },
    {
      field: 'join_time', headerName: 'Join time (UTC)', width: 100, sortable: false,
      valueGetter: ({ value }) => value ? moment(value).utc().format('HH:mm') : 'NA'
    },
    {
      field: 'leave_time', headerName: 'Leave time (UTC)', width: 100, sortable: false,
      valueGetter: ({ value }) => value ? moment(value).utc().format('HH:mm') : 'NA'
    },
    { field: 'join_method', headerName: 'Join Via', width: 120, sortable: false },
    { field: 'device', headerName: 'OS', width: 120, sortable: false },
    { field: 'location', headerName: 'Location', width: 120, sortable: false },
    {
      field: 'video', headerName: 'Video Permission', width: 150, sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.video ? <b>Yes</b> : 'No'}
          </>
        )
      }
    },
    {
      field: 'audio', headerName: 'Audio Permission', width: 150, sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.audio ? <b>Yes</b> : 'No'}
          </>
        )
      }
    },
    {
      field: 'screen_share', headerName: 'Screen Sharing', width: 150, sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.screen_share ? <b>Yes</b> : 'No'}
          </>
        )
      }
    },
    {
      field: 'kill_switch_used', headerName: 'Kill Switch Used', width: 150, sortable: false,
      renderCell: (params) => {
        const ksv = params.value;
        const identity = params.row.identity.split('###')[0] || '';
        const identifier = params.row.identity.split('###')[1] || '';

        if (ksv === true) {
          return <b>Yes</b>;
        } else if (identity === 'Chaperoning Mode' || identifier.startsWith('CP##')) {
          return "No";
        } else {
          return '-';
        }
      }
    }
  ];

  return (
    <main className={classes.content}>

      <div className={classes.toolbar}>
        <div className='logo'>
          <img src={logo} alt="" />
        </div>
        <div className='title'>
          <span className='headerText'>
            <TimelapseOutlined fontSize="large" className='headerIcon' />View Interaction
          </span>
        </div>
      </div>

      <div className={classes.sectionTitle}>
        <Link to={'/'}>
          <Button className={classes.actionButton}>
            {/* <KeyboardBackspace></KeyboardBackspace> */}
            Back
          </Button>
        </Link>
        <Typography variant="h6" className={classes.centerText} >Interaction Overview</Typography>
      </div>

      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <TableContainer className={classes.detailedTable} >
              <Table>
                <TableBody>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Twilio ID :</TableCell>
                    <TableCell >{meeting.meeting_id}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Topic :</TableCell>
                    <TableCell >{meeting.topic}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Maven ID :</TableCell>
                    <TableCell >{meeting.maven_id}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Participants :</TableCell>
                    <TableCell >{data.length}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Paper className={classes.paper}>
            <List >
                <ListItem>
                  Twilio ID : {meeting.meeting_id}
                </ListItem>
                <ListItem>
                  Topic : {meeting.topic}
                </ListItem>
                <ListItem>
                  Maven ID : {meeting.maven_id}
                </ListItem>
                <ListItem>
                  Participants : {data.length}
                </ListItem>
              </List>
            </Paper> */}
          </Grid>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <TableContainer className={classes.detailedTable}>
              <Table>
                <TableBody>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Meeting date :</TableCell>
                    <TableCell >{moment(meeting.datetime).utc().format('DD/MM/YYYY')}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Start time (UTC) :</TableCell>
                    <TableCell >{meeting.started_at ? moment(meeting.started_at).utc().format('HH:mm') : "NA"}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >End time (UTC) :</TableCell>
                    <TableCell >{meeting.ended_at ? moment(meeting.ended_at).utc().format('HH:mm') : "NA"}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Duration :</TableCell>
                    <TableCell >{(meeting.ended_at && meeting.started_at) ? moment(meeting.ended_at).diff(moment(meeting.started_at), 'minutes') + ' minute(s)' : "NA"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={3} lg={3} xl={3}>
            <TableContainer className={classes.detailedTable} >
              <Table >
                <TableBody>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Recording :</TableCell>
                    <TableCell >{meeting.record_meeting ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Anonymous :</TableCell>
                    <TableCell >{meeting.is_anonymous ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Disclaimer :</TableCell>
                    <TableCell >{meeting.show_disclaimer ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableLabel} >Archived | Deleted :</TableCell>
                    <TableCell >{meeting.is_archived ? <span className={classes.dangerText} >Yes </span> : 'No '}|{meeting.is_deleted ? <span className={classes.colorRed} > Yes</span> : ' No'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3}>
            <TableContainer className={classes.detailedTable} >
              <Table>
                <TableBody>
                  <TableRow >
                    <TableCell className={classes.tableButton}>
                      <Button onClick={meeting.record_meeting ? () => downloadFile('recording') : () => { }} disabled={!meeting.record_meeting} className={classes.actionButton} >
                        Recording
                      </Button>
                    </TableCell>
                    <TableCell className={classes.tableButton}>
                      {
                        (meeting.started_at && !meeting.ended_at) ?
                          <Button href={meeting.join_link} target="_blank" className={classes.actionButton}>
                            Join Meeting
                          </Button>
                          :
                          <Button disabled={true} className={classes.actionButton}>
                            Join Meeting
                          </Button>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.tableButton}>
                      <Button onClick={meeting.record_meeting ? () => downloadFile('transcript') : () => { }} disabled={!meeting.record_meeting} className={classes.actionButton} >
                        Transcript
                      </Button>
                    </TableCell>
                    <TableCell className={classes.tableButton}>
                      {
                        (meeting.started_at && !meeting.ended_at) ?
                          <a href={meeting.chaperoning_link} target="_blank" >
                            <Button className={classes.actionButton}>
                              Chaperone Meeting
                            </Button>
                          </a>
                          :
                          <Button disabled={true} className={classes.actionButton}>
                            Chaperone Meeting
                          </Button>
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.sectionTitle}>
              <Typography variant="h6" className={classes.centerText} >Participant Overview</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div className='customRowTable'>
                <div className='Flip'>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    pageSize={25}
                    rowsPerPageOptions={[10, 25, 50]}
                    disableSelectionOnClick
                    autoHeight={true}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      {/* <div className='meetings'>
      <div className="pageHeader">
        <div className="headerLeft">
          <img src={logo} alt="" />
        </div>
        <div className="headerRight">
          <span className="pageHeaderTitle">
            <TimelapseOutlined className='pageHeaderIcon' />View Interaction
          </span>
        </div>
      </div>
      <Grid container className={classes.tableHeader} spacing={3}>
        <Grid item xs={12} sm={2}>
          <div>
            <Link to={'/'}>
              <button className="actionElementButton">
                <KeyboardBackspace></KeyboardBackspace>
                <span >Back</span>
              </button>
            </Link>
          </div>
        </Grid>
      </Grid>

      <div className='title'>Interaction Overview</div>

      <div className="meetingparentdetails">
        <Grid container spacing={1}>
          <Grid container item xs={4} spacing={1} >
            <table>
              <tbody>
                <tr>
                  <td><span className='p-r-20' >Twilio ID</span></td>
                  <td>{meeting.meeting_id}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Topic</span></td>
                  <td>{meeting.topic}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Maven ID</span></td>
                  <td>{meeting.maven_id}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Participants</span></td>
                  <td>{data.length}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid container item xs={3} spacing={1} >
            <table>
              <tbody>
                <tr>
                  <td><span className='p-r-20'>Meeting date</span></td>
                  <td>{moment(meeting.datetime).utc().format('YYYY/MM/DD')}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Start time (UTC)</span></td>
                  <td>{meeting.started_at ? moment(meeting.started_at).utc().format('HH:mm') : "NA"}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>End time (UTC)</span></td>
                  <td>{meeting.ended_at ? moment(meeting.ended_at).utc().format('HH:mm') : "NA"}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Duration</span></td>
                  <td>{(meeting.ended_at && meeting.started_at) ? moment(meeting.ended_at).diff(moment(meeting.started_at), 'minutes') + ' minute(s)' : "NA"}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid container item xs={2} spacing={1} >
            <table>
              <tbody>
                <tr>
                  <td><span className='p-r-20'>Recording</span></td>
                  <td>{meeting.record_meeting ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Anonymous</span></td>
                  <td>{meeting.is_anonymous ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Disclaimer</span></td>
                  <td>{meeting.show_disclaimer ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td><span className='p-r-20'>Archived | Deleted</span></td>
                  <td> 
                    <span className={meeting.is_archived ? "color-red" : ""}>{meeting.is_archived ? 'Yes ' : 'No '}</span>|
                    <span className={meeting.is_deleted ? "color-red" : ""}>{meeting.is_deleted ? ' Yes' : ' No'}</span> 
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid container item xs={3} spacing={1} >
            <table>
              <tbody>
                <tr>
                  <td>
                    <button onClick={meeting.record_meeting ? () => downloadFile('recording') : () => { }} disabled={!meeting.record_meeting} type='button' className="actionElementButton">
                      <span>Recording</span>
                    </button>
                  </td>
                  <td>
                    {
                      (meeting.started_at && !meeting.ended_at) ?
                        <a href={meeting.join_link} className='link' target="_blank" >
                          <button type='button' className="actionElementButton">
                            <span>Join Meeting</span>
                          </button>
                        </a>
                        :
                        <button disabled={true} type='button' className="actionElementButton">
                          <span>Join Meeting</span>
                        </button>
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    <button type='button' onClick={meeting.record_meeting ? () => downloadFile('transcript') : () => { }} disabled={!meeting.record_meeting} className="actionElementButton">
                      <span>Transcript</span>
                    </button>
                  </td>
                  <td>
                    {
                      (meeting.started_at && !meeting.ended_at) ?
                        <a href={meeting.chaperoning_link} className='link' target="_blank" >
                          <button type='button' className="actionElementButton">
                            <span>Chaperone Meeting</span>
                          </button>
                        </a>
                        :
                        <button disabled={true} type='button' className="actionElementButton">
                          <span>Chaperone Meeting</span>
                        </button>
                    }
                  </td>
                </tr>
              </tbody>
            </table>

          </Grid>
        </Grid>
      </div>
      <div className='title'>Participant Overview</div>
      <div className="meetingparentdetails">
        <div className="Child">
          <div className="childdetails">
            <div className="childtable">
              <div className='Flip'>
                <DataGrid
                  rows={data}
                  columns={columns}
                  pageSize={25}
                  rowsPerPageOptions={[10, 25, 50]}
                  disableSelectionOnClick
                  autoHeight={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </main>
  )
}
