
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const axios = require('axios');

const { REACT_APP_API_BASE_URL } = process.env;

const axiosInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(config => {
  const token = cookies.get('d_token');
  const api_key = cookies.get('d_key');
  if (token) {
    config.headers['X-API-KEY'] = api_key
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
},
  error => {
    Promise.reject(error)
  }
)


// axiosInstance.interceptors.response.use(function (res) {
//   return res;
// }, function (error) {
//   const originalRequest = error.config
//   // console.log("originalRequest:::", originalRequest);

//   if (error.response.status === 401 && originalRequest.url.endsWith('/refresh-token')) {
//     cookies.remove('cs_token');
//     cookies.remove('cs_user');
//     cookies.remove('cs_retoken');
//     window.location = '/';
//     return Promise.reject(error);
//   }

//   if (error.response.status === 401 && !originalRequest._retry) {
//     originalRequest._retry = true;
//     const refreshToken = cookies.get('cs_retoken');

//     return axiosInstance.post(
//       '/refresh-token',
//       {
//         refresh_token: refreshToken
//       })
//       .then(res => {
//         if (res.status === 200) {
//           cookies.set('cs_token', res.data.accessToken);
//           originalRequest.headers['Authorization'] = 'Bearer ' + res.data.accessToken;
//           return axios(originalRequest);
//         }
//       })
//   }
//   return Promise.reject(error);
// });

export default axiosInstance;
