import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const StateContext = createContext(null);

export async function login(token) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: {
      'content-type': 'application/json'
    },
  });
}

export default function AppStateProvider(props) {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const api_key = cookies.get('d_key');
    const token = cookies.get('d_token');
    const user = token ? jwt_decode(token) : "";

    if (api_key && user) {
      setIsLoggedIn(true);
      setUser(user)
    } else {
      cookies.remove('d_token');
      setIsLoggedIn(false);
      history.replace('/');
    }
  }, [history]);

  const signIn = useCallback(async (token) => {
    return login(token.credential).then(async (response) => {
      const jsonResponse = await response.json();
      // console.log("jsonResponse", jsonResponse);
      if (response.ok && jsonResponse) {
        try {
          setIsLoggedIn(true);
          setUser(jwt_decode(token.credential));
          cookies.set('d_key', jsonResponse.d_key);
          cookies.set('d_token', token.credential);
          return ({ success: true });
          // resolve();
        } catch (error) {
          console.log(error);
          alert("Error while authenticating");
          return ({ success: false });
          // reject();
          // throw new Error("Error while authenticating");
        }
      } else {
        alert(jsonResponse?.message);
        return ({ success: false });
      }
    });
    // return new Promise((resolve, reject) => {
    //   try {
    //     setIsLoggedIn(true);
    //     setUser(jwt_decode(token.credential));
    //     cookies.set('d_token', token.credential);
    //     resolve();
    //   } catch (error) {
    //     console.log(error);
    //     alert("Error while authenticating");
    //     reject();
    //     // throw new Error("Error while authenticating");
    //   }
    // })
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    setIsLoggedIn(false);
    cookies.remove('d_token');
    cookies.remove('d_key');
    history.replace('/');
  }, []);

  return (
    <StateContext.Provider value={{ user, isLoggedIn, signIn, signOut }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
