import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ThemeProvider } from '@material-ui/core/styles';

import App from './App';
import Login from './components/Login/Login';
import AppStateProvider, { useAppState } from './state';

import * as Sentry from "@sentry/react";

import { MetricsProvider } from '@cabify/prom-react';

import theme from './theme';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'production',
    // debug: true
  });
}

function Main() {
  const { isLoggedIn } = useAppState();

  if (isLoggedIn) {
    return <App />;
  }
  return <>
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
    </Switch>
  </>
    ;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const normalizePath = (path) => {
  const match = path.match(/\/products\/(\d+)/);
  if (match) {
    return `/products/:id`;
  }
  return path;
};

root.render(
  // <MetricsProvider
  //   appName="dashboard-app"
  //   owner="dialectica"
  //   getNormalizedPath={normalizePath}
  //   metricsAggregatorUrl="https://some-metrics-aggregator.com/push-metrics"
  // >
  <Router>
    <ThemeProvider theme={theme}>
      <AppStateProvider>
        <Main />
      </AppStateProvider>
    </ThemeProvider>
  </Router>
  // </MetricsProvider>
);
