import { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';

// import './login.css';
import logo from '../images/logo.svg';
import { useAppState } from '../../state';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    justifyContent: 'center',
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    '& img': {
      maxWidth: '350px'
    }
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    // padding: '0px 60px'
  }
}));

function Login() {
  const classes = useStyles();

  const { signIn } = useAppState();

  const history = useHistory();
  const location = useLocation();

  const handleCredentialResponse = (response) => {
    signIn(response)
      .then((response) => {
        if (response.success) {
          history.replace(location?.state?.from || { pathname: `/` });
        }
      })
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse
    })

    google.accounts.id.renderButton(
      document.getElementById("gSigninButton"),
      { theme: 'outline', size: 'large' }
    )
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={8} md={7} className={classes.image}>
        <img src={logo} alt="" width="auto" height="120px" />
      </Grid>
      <Grid item xs={12} sm={4} md={5} component={Paper} elevation={6} square className={classes.paper}>
        <div id="gSigninButton"></div>
      </Grid>
      {/* <div className='userlogin'>
        <div className="userLoginContainer">
          <div className="userloginLeft">
            <span className="logoadmin"><img src={logo} alt="" width="auto" height="120px" /></span>
          </div>
          <div className="userLoginRight">
            <div id="gSigninButton"></div>
          </div>
        </div>
      </div> */}
    </Grid >
  )
}

export default Login;
