import Sidebar from './components/Sidebar/Sidebar';
import './app.css'
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from "react-router-dom";
import { createBrowserHistory } from 'history';

import Overview from './components/Overview/Overview';
import Interaction from './components/Interaction/Interaction';
// import Meetings from './components/Meetings/Meetings';
// import Participants from './components/Participants/Participants';
// import Topbar from './components/Topbar/Topbar';

import { DashboardOutlined } from '@material-ui/icons';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiTableCell-body': {
      fontFamily: "'Source Sans Pro', sans- serif"
    }
  }
}));

const PrivateRoutesConfig = [
  {
    component: Overview,
    path: '/',
    icon: <DashboardOutlined fontSize="large" />,
    exact: true,
  }, {
    component: Interaction,
    path: '/interaction/:meetingId',
    title: 'Interaction Monitoring',
  }
  // , {
  //   component: Meetings,
  //   path: '/meeting/:meetingId',
  //   title: 'Meeting',
  // }, {
  //   component: Participants,
  //   path: '/room/:roomId',
  //   title: 'Participant',
  // }
];

function MapAllowedRoutes({ routes, isAddNotFound }) {
  return (
    <Switch>
      {routes.map((route) => {
        const {
          path,
          component: Component,
          children,
          title,
          permission,
          ...rest
        } = route;

        return (
          <Route
            {...rest}
            key={path}
            path={`${path}`}
          >
            <Component children={children} />
          </Route>
        )
      })}

      {/* {isAddNotFound && <Route><NotFound /></Route>} */}
      {isAddNotFound && <Route><Overview /></Route>}  {/*Temporary redirected to Overview page */}
    </Switch>
  )
}

function isArrayWithLength(arr) {
  return (Array.isArray(arr) && arr.length)
}

function getAllowedRoutes(routes, userRole) {
  return routes.filter(({ permission }) => {
    if (!permission) {
      return true;
    } else if (!isArrayWithLength(permission)) {
      return true;
    } else {
      const intersected = permission.filter(value => value === userRole);
      return intersected.length;
    }
  });
}

function App() {
  const classes = useStyles();

  const match = useRouteMatch();
  let allowedRoutes = getAllowedRoutes(PrivateRoutesConfig, 'admin');

  return (
    <div className={classes.root}>
      {/* <Topbar /> */}
      <CssBaseline />
      <Router history={createBrowserHistory({})}>
        <Switch>
          <Route path="">
            <Sidebar
              routes={allowedRoutes}
              path={match?.path}
            />
            <MapAllowedRoutes
              routes={allowedRoutes}
              isAddNotFound
            />
          </Route>
        </Switch>
      </Router>
    </div>
  );


}

export default App;

